import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : "https://aues-dashboard-v2-production.up.railway.app";

function Login() {
  const [identifier, setIdentifier] = useState(""); // Could be email or username
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (identifier.includes("@")) {
      // 🔐 Firebase Login (Admin)
      try {
        await signInWithEmailAndPassword(auth, identifier, password);
        onAuthStateChanged(auth, (user) => {
          if (user) navigate("/");
        });
      } catch (error) {
        setMessage("Invalid Firebase credentials. Try again.");
      }
    } else {
      // 🧑‍💼 Committee Username Login
      try {
        console.log("API_BASE_URL", API_BASE_URL);
        const res = await fetch(`${API_BASE_URL}/api/committee-login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: identifier, password }),
        });

        const data = await res.json();

        if (!res.ok) {
          setMessage(data.error || "Login failed");
        } else {
          // Optionally store session or token if needed
          sessionStorage.setItem("committeeUser", identifier);
          navigate("/");
        }
      } catch (error) {
        console.error("Committee login error:", error);
        setMessage("Something went wrong.");
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-br from-red-900 to-purple-700 text-white">
      {/* Background Blur & Overlay */}
      <div className="absolute inset-0 bg-black/30 backdrop-blur-lg"></div>

      {/* Login Card */}
      <div className="relative w-full max-w-md p-8 bg-white/20 backdrop-blur-md shadow-xl rounded-lg border border-white/30">
        <h1 className="text-3xl font-extrabold text-white text-center">🔐 AUES Dashboard</h1>
        <p className="text-center text-gray-200 mt-1">Sign in to continue</p>

        <form onSubmit={handleLogin} className="mt-6">
          <div>
          <label className="text-white text-sm font-medium flex items-center gap-1">
  First Name
  <span className="relative group cursor-pointer">
    <svg
      className="w-4 h-4 text-gray-300 hover:text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M18 10A8 8 0 11 2 10a8 8 0 0116 0zm-9-3a1 1 0 112 0 1 1 0 01-2 0zm2 2a1 1 0 00-2 0v4a1 1 0 002 0v-4z"
        clipRule="evenodd"
      />
    </svg>
    <div className="absolute left-6 top-1 w-64 bg-black text-white text-xs rounded-md shadow-lg p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10">
      If admin account, please login with admin email and password
    </div>
  </span>
</label>
            <input
              type="text"
              placeholder="First Name"
              className="w-full p-3 bg-white/30 border border-white/50 rounded-lg text-white placeholder-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
          </div>

          <div className="mt-4">
            <label className="text-white block text-sm font-medium">Password</label>
            <input
              type="password"
              placeholder="••••••••"
              className="w-full p-3 bg-white/30 border border-white/50 rounded-lg text-white placeholder-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="w-full mt-6 bg-blue-500 hover:bg-blue-600 transition-all duration-300 text-white font-bold py-3 rounded-lg shadow-md focus:outline-none focus:ring-4 focus:ring-blue-400"
          >
            Sign In
          </button>
        </form>

        {/* Error Message */}
        {message && <p className="mt-3 text-red-300 text-center">{message}</p>}

        {/* Bottom Text */}
        
      </div>
    </div>
  );
}

export default Login;
