import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Members from "./pages/Members";
import Shirts from "./pages/Shirts";
import AddCommittee from "./pages/AddCommittee";
import { auth } from "./firebase";
import { Analytics } from "@vercel/analytics/react"


function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute user={user}><Dashboard /></ProtectedRoute>} />
        <Route path="/members" element={<ProtectedRoute user={user}><Members /></ProtectedRoute>} />
        <Route path="/shirts" element={<ProtectedRoute user={user}><Shirts /></ProtectedRoute>} />
        <Route path="/AddCommittee" element={<ProtectedRoute user={user}><AddCommittee /></ProtectedRoute>} />
        
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

// Protected Route (Only allow authenticated users)
const ProtectedRoute = ({ children, user }) => {
  const isCommittee = sessionStorage.getItem("committeeUser");
  return user || isCommittee ? children : <Navigate to="/login" />;
};


export default App;
