// utils/fetchDashboardData.js

const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : "https://aues-dashboard-v2-production.up.railway.app";

export const fetchDashboardData = async (
  setMemberCount,
  setRecentMembers,
  setActivityLog,
  setPendingOrdersCount,
  setMembershipsToday
) => {
  try {
    const [
      membersRes,
      recentMembersRes,
      activityLogRes,
      ordersRes,
      membershipsTodayRes,
    ] = await Promise.all([
      fetch(`${API_BASE_URL}/api/members`),
      fetch(`${API_BASE_URL}/api/recent-members`),
      fetch(`${API_BASE_URL}/api/recent-activity`),
      fetch(`${API_BASE_URL}/api/orders`),
      fetch(`${API_BASE_URL}/api/memberships-today`),
    ]);

    const membersData = await membersRes.json();
    const recentMembersData = await recentMembersRes.json();
    const activityLogData = await activityLogRes.json();
    const ordersData = await ordersRes.json();
    const membershipsTodayData = await membershipsTodayRes.json();

    const pendingOrders = ordersData.filter((order) => order.status === "pending").length;

    setMemberCount(membersData.members);
    setRecentMembers(recentMembersData);
    setActivityLog(activityLogData);
    setPendingOrdersCount(pendingOrders);
    setMembershipsToday(membershipsTodayData.membershipsToday);
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
  }
};
