import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database, ref, onValue } from "../firebase";
import Navbar from "../components/Navbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import confetti from "canvas-confetti";
import AOS from 'aos';
import 'aos/dist/aos.css';


AOS.init();


const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : "https://aues-dashboard-v2-production.up.railway.app";

function Shirts() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const ordersRef = ref(database, "orders");
  
    const unsubscribe = onValue(ordersRef, (snapshot) => {
      if (snapshot.exists()) {
        const allOrders = Object.values(snapshot.val());
        setOrders(allOrders);
  
        const noPendingOrders = allOrders.every(order => order.status !== "pending");
        if (noPendingOrders) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });
        }
      } else {
        setOrders([]);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      }
  
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);
  

  const filteredOrders = orders.filter(
    (order) =>
      order.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.orderNumber.toString().includes(searchQuery)
  );

  let num = 0
  orders.forEach(order => {
    order.items.forEach(item => {
      num = num + item.quantity
    })
  })

  console.log(num)

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const username =
        auth.currentUser?.email || sessionStorage.getItem("committeeUser") || "Unknown";

      await fetch(`${API_BASE_URL}/api/orders/${orderId}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Optionally include Firebase token if logged in
          ...(auth.currentUser && {
            Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
          }),
        },
        body: JSON.stringify({ status: newStatus, username }),
      });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };





  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };




  return (
    <div  className="min-h-screen bg-gray-100  transition-all duration-300">
      <Navbar />
      <div
        className={`fixed top-5 right-5 z-50 transition-opacity duration-300 ${isVisible ? "opacity-100" : "opacity-0"}`}
      >
        {isVisible && (
          <button
            onClick={scrollToTop}
            className="p-2 rounded-full shadow-lg bg-red-500 hover:bg-red-700 text-white w-24 "
          >
            TOP
            &#8679;
          </button>
        )}
      </div>
      <div className="max-w-7xl mx-auto mt-10 px-6">
      <h2
  className="text-4xl font-extrabold text-center text-gray-900 te mb-3 cursor-pointer"
  onClick={async () => {
    setLoading(true);
    try {
      await fetch(`${API_BASE_URL}/api/fetch-orders`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      // Slight delay to let Firebase update before onValue reflects it
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setLoading(false);
    }
  }}
>
  Orders Dashboard
</h2>

        <div className="flex justify-center gap-10 mb-3">
          <a className="text-xl" href="#⏳ Pending Orders">
            ⏳
          </a>
          <a className="text-xl" href="#📦 Packed Orders">
            📦
          </a>
          <a className="text-xl" href="#✅ Fulfilled Orders">
            ✅
          </a>
        </div>
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search by Name or Order Number..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 "
          />
        </div>

        {loading ? (
          <SkeletonOrderSections />
        ) : (
          <>
            <OrderSection title="⏳ Pending Orders" orders={filteredOrders} status="pending" updateOrderStatus={updateOrderStatus} />
            <OrderSection title="📦 Packed Orders" orders={filteredOrders} status="packed" updateOrderStatus={updateOrderStatus} />
            <OrderSection title="✅ Fulfilled Orders" orders={filteredOrders} status="fulfilled" updateOrderStatus={updateOrderStatus} />
          </>
        )}
      </div>
    </div>
  );
}

const SkeletonOrderSections = () => (
  <div>
    {["⏳ Pending Orders", "📦 Packed Orders", "✅ Fulfilled Orders"].map((title, index) => (
      <div key={index} className="mb-12">
        <h3 id={title} className="text-2xl font-bold text-gray-800  mb-6 border-b-2 pb-2">
          {title} : -
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[...Array(3)].map((_, i) => (
            <SkeletonOrderCard key={i} />
          ))}
        </div>
      </div>
    ))}
  </div>
);

// Skeleton Loader for Individual Order Cards
const SkeletonOrderCard = () => (
  <div className="relative bg-gradient-to-br from-gray-200 to-white  p-5 rounded-2xl shadow-xl border border-gray-200 backdrop-blur-lg overflow-hidden animate-pulse min-h-[300px]">
    {/* Top Glow Accent */}
    <div className="absolute top-[-30px] left-[-30px] w-60 h-60 bg-pink-500/10 rounded-full blur-3xl z-0"></div>

    {/* Header */}
    <div className="relative z-10 flex items-center justify-between mb-4">
      <div>
        <Skeleton height={20} width={100} />
        <Skeleton height={12} width={80} style={{ marginTop: 4 }} />
      </div>
      <Skeleton height={20} width={60} />
    </div>

    {/* Items */}
    <div className="relative z-10 space-y-3 mb-4">
      {[...Array(2)].map((_, i) => (
        <div key={i} className="flex items-center gap-3">
          <Skeleton height={48} width={48} borderRadius={8} />
          <div className="flex-1">
            <Skeleton height={14} width="80%" />
            <Skeleton height={10} width="50%" style={{ marginTop: 4 }} />
          </div>
          <Skeleton height={14} width={20} />
        </div>
      ))}
    </div>

    {/* Buttons */}
    <div className="relative z-10 space-y-2">
      <Skeleton height={36} width="100%" borderRadius={12} />
    </div>

    {/* Footer */}
    <div className="mt-4 text-right">
      <Skeleton height={10} width="40%" />
    </div>
  </div>
);



const OrderSection = ({ title, orders, status, updateOrderStatus }) => (
  <div className="mb-12">
    <h3 id={title} className="text-2xl font-bold text-gray-800  mb-6 border-b-2 pb-2">
      {title} : {orders.filter(order => order.status === status).length}
    </h3>
    {orders.filter((order) => order.status === status).length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {orders
          .filter((order) => order.status === status)
          .map((order) => (
            <OrderCard key={order.orderId} order={order} updateOrderStatus={updateOrderStatus} />
          ))}
      </div>
    ) : (
      <p className="text-gray-600 italic">No orders in this category.</p>
    )}
  </div>
);

const OrderCard = ({ order, updateOrderStatus }) => {
  const lastActivity = order.activity
    ? Object.values(order.activity).sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
      )[0]
    : null;

  const editedBy = lastActivity?.editedBy || "server";

  const statusThemes = {
    pending: "bg-yellow-500/20 text-yellow-600 border-yellow-500",
    packed: "bg-blue-500/20 text-blue-600 border-blue-500",
    fulfilled: "bg-green-500/20 text-green-600 border-green-500",
  };

  const statusBackground = {
    pending: "from-yellow-100",
    packed: "from-blue-100",
    fulfilled: "from-green-100",
  };

  return (
    <div data-aos="fade-up" className={`relative bg-gradient-to-br ${statusBackground[order.status]} to-white p-5 rounded-2xl shadow-xl border border-gray-200 backdrop-blur-lg overflow-hidden group transition-all hover:scale-[1.01]`}>
      {/* Top Glow Accent */}
      <div className="absolute top-[-30px] left-[-30px] w-60 h-60 bg-pink-500/10 rounded-full blur-3xl z-0"></div>

      {/* Header */}
      <div className="relative z-10 flex items-center justify-between mb-4">
        <div>
          <h2 className="text-lg font-bold text-gray-900">{order.customerName}</h2>
          <p className="text-xs text-gray-500">Order #{order.orderNumber}</p>
        </div>
        <div className={`px-3 py-1 text-xs font-semibold rounded-full border ${statusThemes[order.status]}`}>
          {order.status.toUpperCase()}
        </div>
      </div>

      {/* Items */}
      <div  className="relative z-10 space-y-3 mb-4">
        {order.items.map((item, idx) => (
          <div key={idx} className="flex items-center gap-3">
            {item.imageUrl ? (
              <img
                src={item.imageUrl}
                alt={item.productName}
                className="w-12 h-12 object-cover rounded-lg border border-gray-300"
              />
            ) : (
              <div className="w-12 h-12 flex items-center justify-center bg-gray-200 text-gray-600 font-bold rounded-lg">
                X
              </div>
            )}
            <div className="flex-1">
              <p className="text-sm font-semibold text-gray-800">{item.productName}</p>
              <p className="text-xs text-gray-500">Size: {item.size}</p>
            </div>
            <span className="text-sm font-semibold text-gray-700">x{item.quantity}</span>
          </div>
        ))}
      </div>

      {/* Buttons */}
      <div className="relative z-10 space-y-2">
        {order.status === "pending" && (
          <button
            onClick={() => updateOrderStatus(order.orderId, "packed")}
            className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded-xl shadow transition"
          >
            Mark as Packed 📦
          </button>
        )}
        {order.status === "packed" && (
          <div className="flex gap-2">
            <button
              onClick={() => updateOrderStatus(order.orderId, "pending")}
              className="w-1/2 bg-gray-500 hover:bg-gray-600 text-white py-2 rounded-xl shadow transition"
            >
              Back ⏳
            </button>
            <button
              onClick={() => updateOrderStatus(order.orderId, "fulfilled")}
              className="w-1/2 bg-green-500 hover:bg-green-600 text-white py-2 rounded-xl shadow transition"
            >
              Fulfill ✅
            </button>
          </div>
        )}
        {order.status === "fulfilled" && (
          <button
            onClick={() => updateOrderStatus(order.orderId, "packed")}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-xl shadow transition"
          >
            Move to Packed 📦
          </button>
        )}
      </div>

      {/* Footer */}
      <p className="relative z-10 text-[11px] text-gray-400 mt-4 italic text-right">
        Modified by: {editedBy}
      </p>
    </div>
  );
};



export default Shirts;
