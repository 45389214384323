import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    auth.signOut();
    navigate("/login");
  };

  const username = auth.currentUser?.email || sessionStorage.getItem("committeeUser") || "Unknown";
  

  return (
    <nav className="top-0 left-0 w-full bg-white/70 backdrop-blur-lg shadow-md px-6 py-4 flex justify-between items-center z-50">
      {/* Logo */}
      <h1 className="text-2xl font-extrabold text-gray-800  tracking-wide">🚀 AUES Dashboard</h1>

      {/* Desktop Menu */}
      <div className="hidden md:flex space-x-6 items-center">
  <button onClick={() => navigate("/")} className="text-gray-700  font-medium hover:text-red-600 transition">
    Home
  </button>
  <button onClick={() => navigate("/members")} className="text-gray-700 font-medium hover:text-red-600 transition">
    Memberships
  </button>
  <button onClick={() => navigate("/shirts")} className="text-gray-700  font-medium hover:text-red-600 transition">
    Shirts
  </button>
  {/* Logout + Name perfectly aligned */}
  <div className="relative flex flex-col items-center">
    <button 
      onClick={handleLogout} 
      className="bg-red-500 text-white px-5 py-2 rounded-full shadow-lg hover:bg-red-600 transition transform hover:scale-105">
      Logout
    </button>
    <p className="absolute top-full text-xs text-white">
      {username}
    </p>
  </div>
</div>




      {/* Hamburger Menu Button (Mobile) */}
      <button
        className="md:hidden flex flex-col space-y-1.5 p-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className={`h-1 w-6 bg-white transition ${menuOpen ? "rotate-45 translate-y-3" : ""}`} />
        <div className={`h-1 w-6 bg-white transition ${menuOpen ? "opacity-0" : ""}`} />
        <div className={`h-1 w-6 bg-white transition ${menuOpen ? "-rotate-45 -translate-y-2" : ""}`} />
      </button>

      {/* Mobile Menu */}
      <div className={`absolute top-16 left-0 w-full bg-white shadow-md flex flex-col items-center space-y-4 py-6 transform transition-all duration-300 ${menuOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0 pointer-events-none"} md:hidden`}>
        <button onClick={() => { navigate("/"); setMenuOpen(false); }} className="text-gray-700 font-medium hover:text-red-600 transition">
          Home
        </button>
        <button onClick={() => { navigate("/members"); setMenuOpen(false); }} className="text-gray-700 font-medium hover:text-red-600 transition">
          Memberships
        </button>
        <button onClick={() => { navigate("/shirts"); setMenuOpen(false); }} className="text-gray-700 font-medium hover:text-red-600 transition">
          Shirts
        </button>
        {/* Logout + Name perfectly aligned */}
  <div className="relative flex flex-col items-center">
    <button 
      onClick={handleLogout} 
      className="bg-red-500 text-white px-5 py-2 rounded-full shadow-lg hover:bg-red-600 transition transform hover:scale-105">
      Logout
    </button>
    <p className="absolute top-full text-xs text-white">
      {username}
    </p>
  </div>
      </div>
    </nav>
  );
};

export default Navbar;
