import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database, ref, get, onValue } from "../firebase";
import Navbar from "../components/Navbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { fetchDashboardData } from "../utils/fetchDashboardData";

const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : "https://aues-dashboard-v2-production.up.railway.app";


function Members() {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      const isCommittee = sessionStorage.getItem("committeeUser");
  
      if (!user && !isCommittee) {
        navigate("/login");
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  useEffect(() => {
    const membersRef = ref(database, "members");

    // 🔥 Real-time listener to update members list instantly when data changes
    const unsubscribe = onValue(membersRef, (snapshot) => {
      if (snapshot.exists()) {
        setLoading(true);
        setMembers(Object.values(snapshot.val()));
      } else {
        setMembers([]);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  // Filtering members based on search input
  const filteredMembers = members.filter((member) =>
    member.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    member.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (member.responses?.["Which of the following best describes your degree?"] || "")
      .toLowerCase()
      .includes(searchQuery.toLowerCase()) ||
    (member.responses?.["What study year are you in?"] || "").toLowerCase().includes(searchQuery.toLowerCase())
  );

  const fetchMembers = async () => {
    try {
      setLoading(true);
  
      // Trigger the backend fetch
      const response = await fetch(`${API_BASE_URL}/api/fetch-members`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
  
      const data = await response.json();
      console.log("Fetch Response:", data);
  
      // Wait a moment to allow Firebase to update
      await new Promise((resolve) => setTimeout(resolve, 2000));
  
    } catch (error) {
      console.error("Error fetching members:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 bg-gradient-to-r text-black p-4">
          AUES Members
        </h1>

        {/* Fetch Members Button - Centered on Mobile */}
        <div className="flex justify-center">
          <button
            onClick={fetchMembers}
            className="bg-red-600 text-white font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition mb-6"
          >
            🔄 Fetch Latest Members
          </button>
        </div>

        {/* Search Bar */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Search members..."
            className="w-full p-4 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Member Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {loading ? (
            <SkeletonMembers />
          ) : filteredMembers.length > 0 ? (
            filteredMembers.reverse().map((member) => (
              <MemberCard key={member.membershipid} member={member} />
            ))
          ) : (
            <p className="text-center col-span-full text-gray-500">No members found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

// Skeleton Loader for Member Cards
const SkeletonMembers = () => (
  <>
    {[...Array(100)].map((_, i) => (
      <div key={i} className="relative bg-white p-6 rounded-xl shadow-lg border transform transition duration-300">
        <Skeleton height={30} width="80%" />
        <Skeleton height={20} width="60%" />
        <div className="mt-2">
          <Skeleton height={20} width="50%" />
          <Skeleton height={20} width="70%" />
        </div>
        <Skeleton height={20} width="80%" />
        <Skeleton height={20} width="60%" />
      </div>
    ))}
  </>
);

const MemberCard = ({ member }) => (
  <div className="relative bg-white p-6 rounded-xl shadow-lg border transform transition duration-300 hover:scale-105">
    {/* Index Badge */}
    <span className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full text-xs font-bold">
      #{member.sortindex}
    </span>

    {/* Member Info */}
    <h2 className="text-lg sm:text-xl font-semibold capitalize text-gray-800">{member.fullname}</h2>
    <p className="text-gray-600 text-sm sm:text-base">{member.email}</p>

    {/* Year & Degree */}
    <div className="mt-2">
      <span className="inline-block bg-green-100 text-green-600 text-xs font-semibold px-2 py-1 rounded-full">
        {member.responses?.["What study year are you in?"] || "N/A"} Year
      </span>
      <p className="mt-2 text-sm text-gray-700 truncate w-full sm:w-auto">
        {member.responses?.["Which of the following best describes your degree?"] || "N/A"}
      </p>
    </div>

    {/* Membership Type & Payment */}
    <p className="text-xs text-gray-500 mt-3">
      Membership: <span className="font-medium text-gray-700">{member.membershiptype}</span>
    </p>
    <p className="text-xs text-gray-500">
      Paid: <span className="font-medium text-gray-700">{member.pricepaid}</span>
    </p>
  </div>
);

export default Members;
