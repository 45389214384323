import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database, ref, onValue } from "../firebase";
import Navbar from "../components/Navbar";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { fetchDashboardData } from "../utils/fetchDashboardData";
import { FaUsers, FaTshirt, FaChartLine, FaClipboardList, FaTicketAlt } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { RiBeerFill } from "react-icons/ri";
import { LuActivity } from "react-icons/lu";
import { MdRecentActors } from "react-icons/md";



const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3002"
    : "https://aues-dashboard-v2-production.up.railway.app";



const Dashboard = () => {
  const navigate = useNavigate();
  const [memberCount, setMemberCount] = useState(null);
  const [recentMembers, setRecentMembers] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [membershipsToday, setMembershipsToday] = useState(0); // New state
  const [cumulativeChartData, setCumulativeChartData] = useState(null); // New state for cumulative graph
  const [username, setUsername] = useState("Unknown");

  const fetchedUsername = auth.currentUser?.email || sessionStorage.getItem("committeeUser") || "Unknown";
  


  


  useEffect(() => {
    fetchDashboardData(setMemberCount, setRecentMembers, setActivityLog, setPendingOrdersCount, setMembershipsToday);
    const fetchData = async () => {
      try {
        const [membersRes, recentMembersRes, activityLogRes, ordersRes] = await Promise.all([
          fetch(`${API_BASE_URL}/api/members`),
          fetch(`${API_BASE_URL}/api/recent-members`),
          fetch(`${API_BASE_URL}/api/recent-activity`),
          fetch(`${API_BASE_URL}/api/orders`),
        ]);

        const membersData = await membersRes.json();
        const recentMembersData = await recentMembersRes.json();
        const activityLogData = await activityLogRes.json();
        const ordersData = await ordersRes.json();

        const pendingOrders = ordersData.filter(order => order.status === "pending").length;

        setMemberCount(membersData.members);
        setRecentMembers(recentMembersData);
        setActivityLog(activityLogData);
        setPendingOrdersCount(pendingOrders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setLoading(false);
      }
    };


    setUsername(fetchedUsername);
    

    
    

    fetchData();


    const membersRef = ref(database, "members");
    const ordersRef = ref(database, "orders");
    const activityRef = ref(database, "activity");


  
    // Members Listener
    onValue(membersRef, (snapshot) => {
      if (!snapshot.exists()) return;

      const today = new Date().toISOString().split("T")[0];
      const allMembers = Object.values(snapshot.val());
      const members = Object.values(snapshot.val());
      const count = members.filter((m) => m.created?.startsWith(today)).length;
      const groupedByDay = {};
      setMembershipsToday(count);

      allMembers.forEach(({ created }) => {
        if (!created) return;
        const dateKey = new Date(created).toISOString().split("T")[0];
        groupedByDay[dateKey] = (groupedByDay[dateKey] || 0) + 1;
      });

      const sortedDates = Object.keys(groupedByDay).sort();
      const dailyData = sortedDates.map(date => groupedByDay[date] || 0);

      // Compute cumulative membership growth
      const cumulativeData = [];
      dailyData.reduce((acc, curr, i) => {
        cumulativeData[i] = acc + curr;
        return cumulativeData[i];
      }, 0);

      setChartData({
        labels: sortedDates,
        datasets: [
          {
            label: "New Members Per Day",
            data: dailyData,
            borderColor: "#EF4444",
            backgroundColor: "rgba(239, 68, 68, 0.2)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });

      setCumulativeChartData({
        labels: sortedDates,
        datasets: [
          {
            label: "Cumulative Membership Growth",
            data: cumulativeData,
            borderColor: "#EF4444",
            backgroundColor: "rgba(239, 68, 68, 0.2)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });

      fetchDashboardData(setMemberCount, setRecentMembers, setActivityLog, setPendingOrdersCount);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-200">
      <Navbar />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">


        {/* GRID LAYOUT */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* === STATS CARDS (TOP) === */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all duration-300">
            <div className="flex items-center gap-3">
              <FaUsers className="text-blue-600 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Total Members</h3>
            </div>
            <p className="text-5xl font-bold text-gray-900 mt-3">
              {loading ? <Skeleton width={80} height={40} /> : memberCount}
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all duration-300">
            <div className="flex items-center gap-3">
              <FaTicketAlt className="text-yellow-500 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Memberships Today</h3>
            </div>
            <p className="text-5xl font-bold text-gray-900 mt-3">
              {loading ? <Skeleton width={80} height={40} /> : membershipsToday}
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all duration-300">
            <div className="flex items-center gap-3">
              <FaTshirt className="text-red-500 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Pending Shirt Orders</h3>
            </div>
            <p className="text-5xl font-bold text-gray-900 mt-3">
              {loading ? <Skeleton width={80} height={40} /> : pendingOrdersCount}
            </p>
          </div>
        </div>

        {/* === CHARTS SECTION (Middle) === */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition">
          <div className="flex items-center gap-3">
              <VscGraph className="text-red-700 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Memberships Per Day</h3>
            </div>
            {loading ? (
              <Skeleton height={250} />
            ) : (
              chartData ? <Line data={chartData} /> : <p className="text-gray-400">No data available.</p>
            )}
          </div>

          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition">
          <div className="flex items-center gap-3">
              <RiBeerFill className="text-yellow-500 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Cumulative Memberships</h3>
            </div>
            {loading ? (
              <Skeleton height={250} />
            ) : (
              cumulativeChartData ? <Line data={cumulativeChartData} /> : <p className="text-gray-400">No data available.</p>
            )}
          </div>
        </div>

        {/* === RECENT ACTIVITY & RECENT MEMBERS (Bottom) === */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          {/* Recent Activity */}
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition">
          <div className="flex items-center gap-3 mb-4">
              <LuActivity className="text-red-500 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Recent Activity</h3>
            </div>
            <ul className="text-gray-900 space-y-4 h-48 overflow-y-auto">
              {loading ? (
                <Skeleton count={4} height={40} />
              ) : (
                activityLog.length > 0 ? (
                  activityLog.map((log, i) => (
                    <li key={i} className="bg-gray-100 p-3 rounded-md">{log.message}</li>
                  ))
                ) : (
                  <p>No recent activity.</p>
                )
              )}
            </ul>
          </div>

          {/* Recent Members */}
          <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition">
          <div className="flex items-center gap-3 mb-4">
              <MdRecentActors className="text-red-500 w-7 h-7" />
              <h3 className="text-md font-medium text-gray-700">Recent Members</h3>
            </div>
            <div className="space-y-4 h-48 overflow-y-auto">
              {loading ? (
                <Skeleton count={4} height={40} />
              ) : (
                recentMembers.length > 0 ? (
                  recentMembers.map((member, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded-md shadow-sm">
                      <span className="text-gray-900 font-medium">{member.name}</span>
                      <span className="text-sm bg-red-500 text-white px-3 py-1 overflow-hidden whitespace-nowrap text-ellipsis rounded-full">
                        {member.degree || "N/A"}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 text-center">No recent members found.</p>
                )
              )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Dashboard;