import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get, onValue } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyA3efHBj84rd52Y0_rHPyqK54CxTrjfNnU",
  authDomain: "auesdash.firebaseapp.com",
  projectId: "auesdash",
  storageBucket: "auesdash.firebasestorage.app",
  databaseURL: "https://auesdash-default-rtdb.asia-southeast1.firebasedatabase.app",
  messagingSenderId: "417157140425",
  appId: "1:417157140425:web:89899dccbb8e3fe6816218",
  measurementId: "G-TD753W89ER"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database, ref, get, onValue };

